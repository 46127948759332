export default {
  primary: {
    color: 'light',
    backgroundColor: 'secondary',
    border: 'solid 1px',
    fontSize: '1rem',
    // fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1rem 3rem',
    ':hover': {
      backgroundColor: 'primary'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '1rem 0.5rem',
    color: 'primary',
    border: 'none',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderColor: 'primary',
    borderRadius: '0px',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '0.85rem',
    ':hover': {
      opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'grey'
    }
  }
}
